import React, { createContext, useContext, useState } from "react";

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [menuItems, setMenuItems] = useState([]);

  const handleMenuState = (itemId, params) => {
    setMenuItems((prevItems) => {
      return prevItems.map((item) => {
        if (item.itemId === itemId) {
          return {
            ...item,
            ...params,
          };
        }
        return item;
      });
    });
  };

  const value = {
    menuItems,
    setMenuItems,
    handleMenuState,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
