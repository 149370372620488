import React, { useEffect, useState } from "react";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";

import {
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Popper,
} from "@mui/material";
import {
  getOriginalStyles,
  getPageIdentifier,
  resetGlobalStyles,
  setOriginalStyles, 
  handleUpdateGlobalDesignObject,
  getGlobalDesignObject
} from "../../Services";
import GoogleAuth from "../GoogleAuth";

const PopupVersionManager = ({
  onClose,
  handleUserEmail,
  token,
  saveDesignObjectState,
  saveDesignObject,
  setVersions,
  versions,
  isTokenValid,
}) => {
  /* useEffect(() => {
    const { versions } = parseVersion();
    setVersions(versions || {});
  }, []); */

  useEffect(() => {
    if (versions != null) {
      let saveStyles = getGlobalDesignObject() || {};
      saveStyles[getPageIdentifier()] = versions;
      console.log("saveStylesTest", saveStyles, versions);
      // localStorage.setItem("elementStyles", JSON.stringify(saveStyles));
      handleUpdateGlobalDesignObject(saveStyles, 'PopupVersionManager useEffect');
    }
  }, [versions]);

  return (
    <DialogContent
      open={true}
      onClose={onClose}
    >
      <DialogTitle>Main</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          {token && (
            <Grid
              item
              xs={12}
            >
              <Button
                variant="contained"
                onClick={() => saveDesignObject({ isManual: true })}
              >
                Save Design Object
              </Button>
              <Grid
                item
                xs={12}
                sx={{ marginTop: "10px" }}
              >
                {saveDesignObjectState === "pending"
                  ? "Saving..."
                  : saveDesignObjectState === "success"
                    ? "Saved Successfully"
                    : saveDesignObjectState === "error"
                      ? "Error Saving"
                      : ""}
              </Grid>
            </Grid>
          )}
          {token && (
            <Grid
              item
              xs={12}
            >
              <Button
                variant="outlined"
                onClick={resetGlobalStyles}
              >
                Populate Original Styles
              </Button>
              {!getOriginalStyles() && (
                <Button
                  variant="outlined"
                  onClick={setOriginalStyles}
                  sx={{ marginLeft: 1 }}
                >
                  Set Original Styles
                </Button>
              )}
            </Grid>
          )}
        </Grid>
        <GoogleAuth
          handleUserEmail={handleUserEmail}
          setVersions={setVersions}
          isTokenValid={isTokenValid}
        />
      </DialogContent>
    </DialogContent>
  );
};

export default PopupVersionManager;
