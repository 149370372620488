import axios from "axios";

export const wpHost = "https://design.codersuccess.com/wp-json";

export async function fetchWPRequest(type, endpoint, params, credentials, host) {
  try {
    if (type === "post") {
      const response = await axios.post(`${host || wpHost}${endpoint}`, params, {
        headers: {
          "X-Username": credentials.login || "gridnev",
          "X-Password": credentials.password || "C*ADw^Geq@zaV2ovla",
          "Content-Type": "application/json",
        },
      });
      return response;
    }
  } catch (error) {
    return error;
  }
}
