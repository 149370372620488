import { iframeElement } from "../Services";

export function colorToHex(color) {
  // Check if already HEX
  if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) {
    return color;
  }

  // Check is RGB or RGBA
  if (/^rgba?\(/.test(color)) {
    // Split parts of rgb
    const parts = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

    // Convert every part to HEX
    const r = parseInt(parts[0]).toString(16).padStart(2, "0");
    const g = parseInt(parts[1]).toString(16).padStart(2, "0");
    const b = parseInt(parts[2]).toString(16).padStart(2, "0");

    // If alpha channel
    if (parts.length === 4) {
      const a = Math.round(parseFloat(parts[3]) * 255)
        .toString(16)
        .padStart(2, "0");
      return `#${r}${g}${b}${a}`;
    }

    return `#${r}${g}${b}`;
  }

  // If format is not RGB/A
  return color;
}

function adjustColor(hex, amount) {
  let usePound = false;

  if (hex[0] === "#") {
    hex = hex.slice(1);
    usePound = true;
  }

  // Разделим цвет на компоненты (с учетом альфа-канала)
  let hasAlpha = hex.length === 8;
  let num = parseInt(hex.slice(0, 6), 16);
  let alpha = hasAlpha ? hex.slice(6) : "";

  let r = (num >> 16) + amount;
  if (r > 255) r = 255;
  if (r < 0) r = 0;

  let g = ((num >> 8) & 0x00ff) + amount;
  if (g > 255) g = 255;
  if (g < 0) g = 0;

  let b = (num & 0x0000ff) + amount;
  if (b > 255) b = 255;
  if (b < 0) b = 0;

  let newColor = (r << 16) | (g << 8) | b;
  let hexColor = newColor.toString(16).padStart(6, "0");

  return (usePound ? "#" : "") + hexColor + alpha;
}

export function getHoverColor(hex) {
  if (!hex) return hex;
  const brightnessThreshold = 130; // Threshold to decide if color is too dark
  const amount = 20; // Amount to lighten or darken
  const rgb = hexToRgb(hex);
  const brightness = 0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b;

  if (brightness > brightnessThreshold) {
    // Color is light, make it darker
    return adjustColor(hex, -amount);
  } else {
    // Color is dark, make it lighter
    return adjustColor(hex, amount);
  }
}

function hexToRgb(hex) {
  hex = hex.replace("#", "");

  let bigint = parseInt(hex.slice(0, 6), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return { r, g, b };
}

export async function getFile(filePath) {
  const res = await fetch(filePath);
  // const res = await fetch("/styles/style.css");
  if (res) {
    const blob = res.blob();
    console.log("getFile res: ", res);
    return blob || null;
  }
}
export async function getFileContent(filePath) {
  try {
    const response = await fetch(filePath);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const content = await response.text();
    return content;
  } catch (error) {
    console.error("Failed to fetch HTML file:", error);
    return null;
  }
}

export async function syncTimeout(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function getElementCoordinates(element) {
  let box = element.getBoundingClientRect();
  const windowElement = iframeElement.contentWindow;

  return {
    top: box.top + windowElement.scrollY,
    right: windowElement.innerWidth - box.right,
    bottom: box.bottom + windowElement.scrollY,
    left: box.left + windowElement.scrollX,
  };
}
