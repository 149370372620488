import {
  Visibility,
  VisibilityOff,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

function Publish({
  handleSavePageToPlatform,
  htmlContentLoading,
  userEmail,
  version,
}) {
  const [showPass, setShowPass] = useState(false);
  const [profilesList, setProfilesList] = useState([
    {
      label: "Landing",
      type: "landing",
      credentials: {
        endpoint: "https://design.codersuccess.com/wp-json",
        login: "gridnev",
        password: "C*ADw^Geq@zaV2ovla",
        email: userEmail,
        page: localStorage.getItem("selectedPage"),
        version: version,
      },
    },
    {
      label: "Shop",
      type: "shop",
      credentials: {
        endpoint: "https://design.codersuccess.com/wp-json",
        login: "gridnev",
        password: "C*ADw^Geq@zaV2ovla",
      },
    },
  ]);
  const [selectedProfile, setSelectedProfile] = useState(profilesList[0]);

  const togglePasswordVisibility = () => {
    setShowPass((prev) => !prev);
  };

  const handleProfileCredentials = (param, value) => {
    setSelectedProfile((profile) => {
      const newProfile = { ...profile };
      profile.credentials[param] = value;
      return newProfile;
    });
    setProfilesList((profilesList) => {
      const newList = [...profilesList];
      const targetProfile = newList.find(
        (prof) => prof.type === selectedProfile.type,
      );
      targetProfile.credentials[param] = value;
      return newList;
    });
  };

  return (
    <DialogContent>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Profile</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedProfile?.type}
              label="Profile"
              onChange={(e) => {
                setSelectedProfile(
                  profilesList.find(
                    (profile) => profile.type === e.target.value,
                  ),
                );
              }}
            >
              {profilesList.map((profile) => (
                <MenuItem
                  value={profile.type}
                  key={profile.type}
                  disabled={profile.type !== "landing"}
                >
                  {profile.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {selectedProfile && (
          <Fragment>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                value={selectedProfile.credentials.endpoint}
                onChange={(e) =>
                  handleProfileCredentials("endpoint", e.target.value)
                }
                label="Endpoint"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                value={selectedProfile.credentials.login}
                onChange={(e) =>
                  handleProfileCredentials("login", e.target.value)
                }
                label="Login"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                value={selectedProfile.credentials.password}
                onChange={(e) =>
                  handleProfileCredentials("password", e.target.value)
                }
                label="Password"
                type={showPass ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={togglePasswordVisibility}
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                value={selectedProfile.credentials.email}
                onChange={(e) =>
                  handleProfileCredentials("email", e.target.value)
                }
                label="Gmail"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                value={selectedProfile.credentials.page}
                onChange={(e) =>
                  handleProfileCredentials("page", e.target.value)
                }
                label="Page"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                fullWidth
                value={selectedProfile.credentials.version}
                onChange={(e) =>
                  handleProfileCredentials("version", e.target.value)
                }
                label="Version"
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                variant="contained"
                onClick={() => handleSavePageToPlatform(selectedProfile)}
                disabled={htmlContentLoading}
                endIcon={htmlContentLoading && <CircularProgress size={16} />}
              >
                Publish {selectedProfile.type}
              </Button>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </DialogContent>
  );
}

export default Publish;
