import React, { useEffect, useState } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { parseVersion } from "../../Services";
import SuccessMessage from "../SuccessMessage";

const PagesTab = ({
  setVersions,
  pageCreated,
  setPageCreated,
  handleCreatePage,
  setOpenedSidebar,
}) => {
  const [pageName, setPageName] = useState("");
  const textToURl = (text) => {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  };

  const handlePageSubmission = (e) => {
    e.preventDefault();
    handleCreatePage(textToURl(pageName));
    setPageName("");
  };

  return (
    <DialogContent
      open={true}
      onClose={() => setOpenedSidebar("")}
    >
      <DialogTitle style={{ marginBottom: "1.5rem", paddingLeft: "0" }}>
        Pages Manager
      </DialogTitle>
      <Grid
        container
        spacing={2}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <TextField
                label="New page name"
                value={pageName}
                onChange={(e) => setPageName(e.target.value)}
                fullWidth
                size="small"
                style={{ minWidth: "200px" }}
              />
            </Grid>
          </Grid>
          <Button
            variant="contained"
            disabled={pageName.length < 3}
            onClick={handlePageSubmission}
            fullWidth
          >
            Create Page
          </Button>
        </Box>
        {pageCreated && (
          <SuccessMessage
            text="Page created successfully!"
            setIsSuccessOpen={setPageCreated}
          />
        )}
      </Grid>
      <DialogActions>
        <Button onClick={() => setOpenedSidebar("")}>Close</Button>
      </DialogActions>
    </DialogContent>
  );
};

export default PagesTab;
