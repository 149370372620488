import React, { useEffect, useState } from 'react'
import './App.css';

import { applyGlobalStyles, getStyleForMode, setElPositionStyles, setImageSourceStyles, setImgElBckPstn, updateDynamicStyles } from './Services';
import { getGlobalDesignObject, getDeviceMode } from './Services.js';

function AppSaticPage() {
  const [currentResolutionMode, setCurrentResolutionMode] = useState(getDeviceMode());
  const [version, setVersion] = useState(null);
  const [dfaultStyles, setDefaultStyles] = useState(null);
  const [pageSlug, setPageSlug] = useState(null);
  const applyImageBackgroundPositionStyles = (savedStyles, resolutionMode) => {
    const elements = document.querySelectorAll('[data-imagePosition="main"]');
    elements.forEach(element => {
      const styles = getStyleForMode(savedStyles, resolutionMode, element.id, "bckgrndPosition", version);

      if (styles) {
        //console.log("debugVerion our text styles", styles);
        setImgElBckPstn(styles.imgPstn, element);
      } else {
        element.removeAttribute("style");
      }

    });
  };

  const applyElPositionStyles = (savedStyles, resolutionMode) => {
    const elements = document.querySelectorAll('[data-elPosition="main"]');
    elements.forEach(element => {
      const styles = getStyleForMode(savedStyles, resolutionMode, element.id, "elPosition", version);

      if (styles) {
        // //console.log("debugVerion our text styles", styles);
        setElPositionStyles(styles, element.id);
      } else {
        element.removeAttribute("style");
      }

    });

  };

  const applyTextAndFontStyles = (savedStyles, resolutionMode) => {
    const elements = document.querySelectorAll('[data-edit="main"]');
    elements.forEach(element => {
      const styles = getStyleForMode(savedStyles, resolutionMode, element.id, "styles", version);

      if (styles) {
        // //console.log("debugVerion our text styles", styles);
        element.style.fontSize = styles.fontSize;
        element.style.color = styles.color;
        if (styles.text != null) {
          element.innerHTML = styles.text;
        }
      }
      // testRemoveStyles
      else {
        element.removeAttribute("style");
      }

    });
  };

  const applyImageSourceStyles = (savedStyles, resolutionMode) => {
    const elements = document.querySelectorAll('[data-image="main"]');
    elements.forEach(element => {
      setImageSourceStyles(savedStyles, resolutionMode, element, version);
      //setElementimagePosition(savedStyles, )
    });
  };


  useEffect(() => {
    // const savedStyles = JSON.parse(localStorage.getItem('elementStyles'));
    const savedStyles = getGlobalDesignObject();
    if (savedStyles == null || savedStyles == undefined) {
      return;
    }
    console.log("savedStyles_useEffect", savedStyles);
    applyImageBackgroundPositionStyles(savedStyles, currentResolutionMode);
    applyElPositionStyles(savedStyles, currentResolutionMode);
    applyTextAndFontStyles(savedStyles, currentResolutionMode);
    applyImageSourceStyles(savedStyles, currentResolutionMode);
    applyGlobalStyles(savedStyles, currentResolutionMode, version);
  }, [currentResolutionMode, version, pageSlug]);



  return null
}

export default AppSaticPage