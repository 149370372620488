import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Popper,
} from '@mui/material';

const PopupBackgroundImagePosition = ({ handleSaveImgBckPstn}) => { 
  const [xPosition, setXPosition] = useState(50);
  const [yPosition, setYPosition] = useState(50);
  const [backgroundSize, setBackgroundSize] = useState('cover');
  const [backgroundRepeat, setBackgroundRepeat] = useState('no-repeat');
  const [backgroundAttachment, setBackgroundAttachment] = useState('fixed');
  const elementRef = useRef(null);

  // Apply settings from localStorage on mount
  useEffect(() => {
    const savedX = localStorage.getItem('bgXPosition');
    const savedY = localStorage.getItem('bgYPosition');
    const savedSize = localStorage.getItem('bgSize');
    if (savedX && savedY) {
      setXPosition(parseInt(savedX, 10));
      setYPosition(parseInt(savedY, 10));
    }
    if (savedSize) {
      setBackgroundSize(savedSize);
    }
  }, []);

  // Update settings and save to localStorage
  useEffect(() => {
    handleSaveImgBckPstn({ xPosition, yPosition, backgroundSize, backgroundRepeat, backgroundAttachment });
  }, [xPosition, yPosition, backgroundSize, handleSaveImgBckPstn,backgroundRepeat,backgroundAttachment]);

  return (
    <DialogContent open={true} >
      <DialogTitle>Background Image Position</DialogTitle>
      <DialogContent>
        <div ref={elementRef} className="target-element" style={{ backgroundImage: 'url(your-image-url)' }}>
        </div>
        <FormControl fullWidth margin="normal">
          <InputLabel style={{marginTop: "10px"}}>Horizontal Position</InputLabel>
          <Slider
            value={xPosition}
            onChange={(e, value) => setXPosition(value)}
            min={0}
            max={100}
            aria-labelledby="horizontal-position-slider"
          />
        </FormControl>
        <FormControl fullWidth margin="normal" >
          <InputLabel style={{marginTop: "10px"}}>Vertical Position</InputLabel>
          <Slider
            value={yPosition}
            onChange={(e, value) => setYPosition(value)}
            min={0}
            max={100}
            aria-labelledby="vertical-position-slider"
          />
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ marginBottom: '16px' }}>
          <InputLabel style={{backgroundColor: "#fff"}}>Background Size</InputLabel>
          <Select value={backgroundSize} onChange={(e) => setBackgroundSize(e.target.value)}>
            <MenuItem value="cover">Cover</MenuItem>
            <MenuItem value="contain">Contain</MenuItem>
            <MenuItem value="auto">Auto</MenuItem>
            <MenuItem value="100% 100%">100% 100%</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ marginBottom: '16px' }}>
          <InputLabel style={{backgroundColor: "#fff"}}>Background Repeat</InputLabel>
          <Select value={backgroundRepeat} onChange={(e) => setBackgroundRepeat(e.target.value)}>
            <MenuItem value="no-repeat">no-repeat</MenuItem>
            <MenuItem value="repeat-x">repeat-x</MenuItem>
            <MenuItem value="repeat">repeat</MenuItem>
            <MenuItem value="space">space</MenuItem>
            <MenuItem value="round">round</MenuItem>
            <MenuItem value="space repeat">space repeat</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal" sx={{ marginBottom: '16px' }}>
          <InputLabel style={{backgroundColor: "#fff"}}>Background Attachment</InputLabel>
          <Select value={backgroundAttachment} onChange={(e) => setBackgroundAttachment(e.target.value)}>
            <MenuItem value="scroll">scroll</MenuItem>
            <MenuItem value="fixed">fixed</MenuItem>
            <MenuItem value="local">local</MenuItem>
            <MenuItem value="initial">initial</MenuItem>
            <MenuItem value="inherit">inherit</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
    </DialogContent>
  );
};

export default PopupBackgroundImagePosition;