import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { getElementPositionAndSize,innerPage } from "../../Services";

const RemoveElements = ({
  sections,
  iframe,
  removedElements,
  handleToggleRemovedItems,
  removeItemsSelectedSection,
}) => {
  // const [selectedSection, setSelectedSection] = useState(null);
  const [isPreviewHidden, setIsPreviewHidden] = useState(false);

  // const handleSectionClick = (section) => {
  //   const element = iframe.querySelector(`[data-section="${section.name}"]`);
  //   if (element.hasAttribute("data-switch-sections")) {
  //     const targetSection = element.querySelector(".swiper-slide-active")
  //       .children[0];
  //     setSelectedSection({ ...section, element: targetSection });
  //   } else {
  //     setSelectedSection({ ...section, element });
  //   }
  // };

  const toggleElementVisibility = (elementId) => {
    if (!elementId) return console.error("Element does not have a unique ID");
    handleDeleteHighlighter();
    // const element = iframe.querySelector(`[data-remove="${elementId}"]`);
    const element = iframe.getElementById(elementId);
    console.log(`toggleElementVisibility element:`, element);
    if (element) {
      handleToggleRemovedItems(element, elementId);
    }
  };

  const previewRemovedElements = (elements) => {
    elements.forEach((elementId) => {
      // const element = iframe.querySelector(`[data-remove="${elementId}"]`);
      const element = iframe.getElementById(elementId);
      if (element && !isPreviewHidden) {
        element.classList.add("preview-hidden");
        setIsPreviewHidden(true);
      } else if (element) {
        element.classList.remove("preview-hidden");
        setIsPreviewHidden(false);
      }
    });
  };

  const handleHighlightHoveredElement = (element) => {
    const { x, y, width, height } = getElementPositionAndSize(element);

    const highlighter = innerPage.createElement("div");
    const highlightColor = "rgba(255, 255, 0, 0.2)";
    highlighter.style.cssText = `position: absolute; top: ${y - 5}px; left: ${
      x - 5
    }px; width: ${width + 10}px; height: ${
      height + 10
    }px; z-index: 1000000000000; background-color: ${highlightColor}; border-radius: 4px; box-shadow: 0 0 8px ${highlightColor}; opacity: 0; transition: opacity 0.15s ease 0s;`;
    highlighter.setAttribute("data-highlighter", "");

    innerPage.body.appendChild(highlighter);
    setTimeout(() => {
      highlighter.style.setProperty("opacity", "1");
    }, 0);
  };
  const handleDeleteHighlighter = () => {
    const highlighters = innerPage.querySelectorAll("[data-highlighter]");
    highlighters.forEach((highlighter) => {
      highlighter.style.setProperty("opacity", "0");
      setTimeout(() => {
        highlighter?.remove();
      }, 150);
    });
  };

  return (
    <DialogContent>
      <Box mb={2}>
        <Button
          variant="contained"
          color={isPreviewHidden ? "primary" : "default"}
          onClick={() => previewRemovedElements(removedElements)}
          style={{ marginBottom: "10px" }}
        >
          {isPreviewHidden ? "Hide Hidden Elements" : "Preview Hidden Elements"}
        </Button>
        <Grid
          container
          spacing={1}
        >
          {/* {sections.map((section) => (
            <Grid
              item
              key={section.name}
            >
              <Button
                variant="contained"
                color={
                  selectedSection?.name === section.name ? "primary" : "default"
                }
                onClick={() => handleSectionClick(section)}
              >
                {section.name}
              </Button>
            </Grid>
          ))} */}
        </Grid>
      </Box>
      {removeItemsSelectedSection && (
        <Box>
          <Typography
            variant="h6"
            gutterBottom
          >
            {removeItemsSelectedSection.name}
          </Typography>
          <Grid
            container
            spacing={2}
          >
            <List
              sx={{
                width: "100%",
                "& .MuiListItem-root": {
                  width: "100%",
                  display: "block",
                },
              }}
            >
              {Array.from(
                removeItemsSelectedSection.element.querySelectorAll(
                  "[data-remove]",
                ),
              ).map((element) => (
                <ListItem
                  key={element.dataset.remove}
                  divider
                  component={({ children, ...other }) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                      {...other}
                    >
                      {children}
                    </div>
                  )}
                >
                  <Typography variant="body1">
                    {element.getAttribute("data-remove")}
                  </Typography>
                  <Button
                    variant="contained"
                    color={
                      removedElements.includes(element.id)
                        ? "default"
                        : "primary"
                    }
                    onClick={() => toggleElementVisibility(element.id)}
                    onMouseEnter={() => handleHighlightHoveredElement(element)}
                    onMouseLeave={() => handleDeleteHighlighter()}
                  >
                    {removedElements.includes(element.id) ? "Show" : "Hide"}
                  </Button>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Box>
      )}
    </DialogContent>
  );
};

export default RemoveElements;
