// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATAXYlNabDTNT9e1Ovty7JAelp3A9FPE4",
  authDomain: "baz-create.firebaseapp.com",
  projectId: "baz-create",
  storageBucket: "baz-create.appspot.com",
  messagingSenderId: "182959729953",
  appId: "1:182959729953:web:44aa55539f665345ede260",
  measurementId: "G-CXNN09R01W"
};


// Initialize Firebase
let auth = null, provider = null;
if (firebaseConfig?.projectId) {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  if (app.name && typeof window !== 'undefined') {
    auth = getAuth(app);
    provider = new GoogleAuthProvider();

  }

}
// Initialize Firebase

export { auth, provider };