import {
  Box,
  Button,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Popper,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect } from "react";
import { getCurrentStylesObject, getGlobalDesignObject, getPageIdentifier,handleUpdateGlobalDesignObject } from "../Services";
import { useMenu } from "./HeaderMenuContext";
import { getNameFromId } from "./menuHelpers";

function EditMenuPopup({
  onClose,
  innerPage,
  version,
  currentResolutionMode,
  saveDesignObject,
}) {
  const theme = useTheme();
  const { menuItems, setMenuItems, handleMenuState } = useMenu();

  const saveMenuState = () => {
    const savedStyles = getGlobalDesignObject() || {};
    savedStyles["menu"] =
      savedStyles["menu"] || {};
    menuItems.forEach((item) => {
      const { itemId, ...rest } = item;
      savedStyles["menu"][
        item.itemId
      ] = { ...rest };
    });

    // localStorage.setItem("elementStyles", JSON.stringify(savedStyles));
    handleUpdateGlobalDesignObject(savedStyles, saveMenuState);

    saveDesignObject();
  };

  useEffect(() => {
    const anchors = innerPage.querySelectorAll("[data-menu-anchor]");
    const savedStyles = getGlobalDesignObject() || {};

    const currentSavedMenuState =
      savedStyles.menu || {};

    const itemsList = [
      ...Array.from(anchors).map((item) => {
        const itemId = item.getAttribute("data-menu-anchor");
        if (currentSavedMenuState[itemId]) {
          return {
            itemId: itemId,
            text: currentSavedMenuState[itemId].text || getNameFromId(itemId),
            state:
              currentSavedMenuState[itemId].state !== null &&
                currentSavedMenuState[itemId].state !== undefined
                ? currentSavedMenuState[itemId].state
                : true,
          };
        } else {
          return {
            itemId: itemId,
            text: getNameFromId(itemId),
            state: true,
          };
        }
      }),
      ...["blog", "shop", "cart"].map((item) => {
        const itemId = item;
        if (currentSavedMenuState[itemId]) {
          return {
            itemId: itemId,
            text: currentSavedMenuState[itemId].text || getNameFromId(itemId),
            state:
              currentSavedMenuState[itemId].state !== null &&
                currentSavedMenuState[itemId].state !== undefined
                ? currentSavedMenuState[itemId].state
                : true,
          };
        } else {
          return {
            itemId: itemId,
            text: getNameFromId(itemId),
            state: true,
          };
        }
      }),
    ];

    setMenuItems(itemsList);
  }, [innerPage, version, currentResolutionMode, setMenuItems]);

  return (
    <Paper elevation={0}>
      <DialogTitle>Edit Menu</DialogTitle>

      <Box
        style={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2, 4, 3),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
        }}
      >
        <Grid
          container
          spacing={3}
        >
          {menuItems.map((item) => (
            <Grid
              item
              xs={12}
              key={item.itemId}
            >
              <Grid
                container
                spacing={2}
                alignItems={"center"}
              >
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={item.state}
                          onChange={(e) =>
                            handleMenuState(item.itemId, {
                              state: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Visible"
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  flexGrow={1}
                >
                  <TextField
                    value={item.text || ""}
                    onChange={(e) =>
                      handleMenuState(item.itemId, {
                        text: e.target.value,
                      })
                    }
                    label="Name"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
          >
            <Button
              variant="contained"
              onClick={() => saveMenuState()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default EditMenuPopup;
