import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { getCurrentStylesObject, getGlobalDesignObject, getPageIdentifier } from "../Services";
import { useMenu } from "./HeaderMenuContext";
import { getNameFromId } from "./menuHelpers";

function HeaderMenu({
  innerPage,
  version,
  handleChangeCurrentVersion,
  currentResolutionMode,
  saveDesignObject
}) {
  const { menuItems, setMenuItems } = useMenu();

  useEffect(() => {
    const anchors = innerPage.querySelectorAll("[data-menu-anchor]");
    // const savedStyles = getCurrentStylesObject(version, currentResolutionMode);
    const savedStyles = getGlobalDesignObject();
    

    const currentSavedMenuState =
      savedStyles.menu || {};

    const itemsList = [
      ...Array.from(anchors).map((item) => {
        const itemId = item.getAttribute("data-menu-anchor");
        if (currentSavedMenuState[itemId]) {
          return {
            itemId: itemId,
            text: currentSavedMenuState[itemId].text || getNameFromId(itemId),
            state:
              currentSavedMenuState[itemId].state !== null &&
              currentSavedMenuState[itemId].state !== undefined
                ? currentSavedMenuState[itemId].state
                : true,
          };
        } else {
          return {
            itemId: itemId,
            text: getNameFromId(itemId),
            state: true,
          };
        }
      }),
      ...["blog", "shop", "cart"].map((item) => {
        const itemId = item;
        if (currentSavedMenuState[itemId]) {
          return {
            itemId: itemId,
            text: currentSavedMenuState[itemId].text || getNameFromId(itemId),
            state:
              currentSavedMenuState[itemId].state !== null &&
              currentSavedMenuState[itemId].state !== undefined
                ? currentSavedMenuState[itemId].state
                : true,
          };
        } else {
          return {
            itemId: itemId,
            text: getNameFromId(itemId),
            state: true,
          };
        }
      }),
    ];

    setMenuItems(itemsList);
  }, [
    innerPage,
    version,
    handleChangeCurrentVersion,
    currentResolutionMode,
    setMenuItems,
  ]);

  const menuPlaces = innerPage?.querySelectorAll(".menu__list") || [];

  return menuPlaces.length > 0
    ? Array.from(menuPlaces).map((place) =>
        createPortal(
          menuItems
            ?.filter((item) => item.state)
            .map((item) => (
              <li
                className="menu__item"
                key={item.itemId}
              >
                <a
                  href={`#${item.itemId}`}
                  className="menu__link header-menu-text-size header-menu-color"
                >
                  {item.text}
                </a>
              </li>
            )),
          place,
        ),
      )
    : null;
}

export default HeaderMenu;
