import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ScopedCssBaseline,
  Button,
  TextField,
  FormControl,
  FormLabel,
  Popper,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { MuiColorInput } from "mui-color-input";
import theme from "../../theme/theme";

const EditTextTab = ({
  onSave,
  onClose,
  initialText,
  initialFontSize,
  initialColor,
  initialFontWeight,
  editTextElemId,
}) => {
  console.log(
    "initialText",
    initialText,
    "initialFontSize",
    initialFontSize,
    "initialColor",
    initialColor,
    "initialFontWeight",
    initialFontWeight,
  );
  const [text, setText] = useState(initialText);
  const [fontSize, setFontSize] = useState(initialFontSize);
  const [color, setColor] = useState(initialColor);
  const [fontWeight, setFontWeight] = useState(initialFontWeight);

  useEffect(() => {
    setText(initialText);
    setFontSize(initialFontSize);
    setColor(initialColor);
    setFontWeight(initialFontWeight);
    console.log(
      "initialText, initialFontSize, initialColor, initialFontWeight: ",
      initialText,
      initialFontSize,
      initialColor,
      initialFontWeight,
    );
  }, [
    initialText,
    initialFontSize,
    initialColor,
    initialFontWeight,
    editTextElemId,
  ]);

  useEffect(() => {
    onSave(text, fontSize, color, fontWeight);
  }, [text, fontSize, color, fontWeight]);

  useEffect(() => {
    function closeOnEsc(e) {
      if (e.key === "Escape") {
        onClose();
      }
    }

    document.addEventListener("keydown", closeOnEsc);

    return () => {
      document.removeEventListener("keydown", closeOnEsc);
    };
  }, []);

  return (
    <DialogContent
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Edit Text</DialogTitle>

      <div
        style={{
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(2, 4, 3),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          maxHeight: "350px",
          overflowY: "auto",
        }}
      >
        <FormControl>
          <FormLabel>Text</FormLabel>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            multiline
            rows={4}
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl>
          <FormLabel>Font Size</FormLabel>
          <TextField
            type="text"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl>
          <FormLabel>Font Weight</FormLabel>
          <TextField
            type="text"
            value={fontWeight}
            onChange={(e) => setFontWeight(e.target.value)}
            variant="outlined"
            fullWidth
          />
        </FormControl>
        <FormControl>
          <FormLabel>Font Color</FormLabel>
          <MuiColorInput
            format="hex8"
            value={color}
            onChange={(color) => setColor(color)}
            fullWidth
          />
        </FormControl>
      </div>
    </DialogContent>
  );
};

export default EditTextTab;
