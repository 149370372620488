import React, { useEffect, useRef, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  ScopedCssBaseline,
  Button,
  TextField,
  FormControl,
  FormLabel,
  Popper,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Switch,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { MuiColorInput } from "mui-color-input";
import theme from "../../theme/theme";
import {
  getCurrentStylesObject,
  getPageIdentifier,
  insertDuplicatedSection,
  updateDynamicStyles,
  handleUpdateGlobalDesignObject,
  getGlobalDesignObject
} from "../../Services";
import { ControlPointDuplicateOutlined, Delete } from "@mui/icons-material";
const SectionsToggle = ({
  sectionsToToggle,
  toggleSectionsRef,
  handleSectionsList,
  version,
  saveDesignObject,
  handleToggleSections,
}) => {
  const orderUpdateTimerRef = useRef(null);

  const handleSectionsSave = (e, sectionId) => {
    const updatedSections = handleToggleSections(e.target.checked, sectionId);
    console.log("sectionDebug event it looks like: ", e);
    console.log("sectionDebug sectionId", sectionId);
    saveGlobalSections(updatedSections);
  };

  const saveGlobalSections = async (stylesObject) => {
    let savedStyles;

    try {
      // Try to parse the global design object
      savedStyles = JSON.parse(getGlobalDesignObject());
    } catch (error) {
      // If parsing fails, it might already be an object
      savedStyles = getGlobalDesignObject() || {};
    }

    console.trace("sectionDebug Service to save", savedStyles);

    // Ensure the necessary structure exists
    savedStyles["saveGlobalSections"] = savedStyles["saveGlobalSections"] || {};
    savedStyles["saveGlobalSections"]["global"] = stylesObject;

    // Convert the entire object to a JSON string
    const jsonObject = JSON.stringify(savedStyles);

    // Update the global design object
    handleUpdateGlobalDesignObject(jsonObject, 'saveGlobalSections');
    saveDesignObject();
  };

  function switchSectionActiveVariantActions(version) {
    if (version) {
      const iframeElement = document.querySelector("#viewport-preview");
      const switchSectionsSwiper =
        iframeElement?.contentWindow?.switchSectionsSwiper || null;
      if (switchSectionsSwiper) {
        if (switchSectionsSwiper.length > 1)
          switchSectionsSwiper.forEach((swiper) => {
            onSwiperActions(swiper);
          });
        else onSwiperActions(switchSectionsSwiper);
      }
    }
    function onSwiperActions(swiper) {
      const onSlideChange = (e) => {
        const sectionChangedParent = e.el.closest("[data-switch-sections]");
        const activeSlideNode = e.slides[e.activeIndex];
        // const sectionChangedParentId = sectionChangedParent.getAttribute(
        //   "data-section-toggle",
        // );
        const sectionChangedParentId = sectionChangedParent.id;
        const activeSectionId = activeSlideNode.children[0].id;

        const newSectionsObject = [...toggleSectionsRef.current];
        const thisSection = newSectionsObject.find(
          (sect) => sect.id === sectionChangedParentId,
        );
        thisSection.activeVariant = activeSectionId;
        saveGlobalSections(newSectionsObject);
      };

      swiper.on("slideChange", onSlideChange);
    }
  }

  const handleSectionOrder = (e, id) => {
    const input = e.target;
    const value = input.value;
    if (!/^\d+$/.test(value)) return;

    const newSectionsList = [...toggleSectionsRef.current];
    const currentSection = newSectionsList.find((section) => section.id === id);
    currentSection.order = Number(value);

    handleSectionsList(newSectionsList);
    const iframeElement = document.querySelector("#viewport-preview");
    //* const targetSectionNode = iframeElement?.contentDocument?.querySelector(
    //*   `[data-section-toggle="${name}"]`,
    //* );
    const targetSectionNode =
      iframeElement?.contentDocument?.getElementById(id);
    if (targetSectionNode) {
      targetSectionNode.style.setProperty("order", value);
      setTimeout(() => {
        iframeElement.contentWindow?.aos?.refreshHard();
      }, 50);
    }

    clearTimeout(orderUpdateTimerRef.current);
    orderUpdateTimerRef.current = setTimeout(() => {
      saveGlobalSections(newSectionsList);
    }, 1000);
  };

  const handleCreateDuplicate = (section, index) => {
    const newSection = {
      ...section,
      name: section.name + " Copy",
      id: section.id + "-copy",
      duplicateId: section.id,
    };

    const newSectionsList = [
      ...toggleSectionsRef.current.slice(0, index + 1),
      newSection,
      ...toggleSectionsRef.current.slice(index + 1),
    ];
    console.log("newSectionsList: ", newSectionsList)
    handleSectionsList(newSectionsList);
    saveGlobalSections(newSectionsList);
    insertDuplicatedSection(newSection);
  };
  const handleDeleteDuplicate = (section) => {
    const newSectionsList = toggleSectionsRef.current.filter(
      (sect) => sect.name !== section.name,
    );
    handleSectionsList(newSectionsList);
    saveGlobalSections(newSectionsList);

    const iframeElement = document.querySelector("#viewport-preview");
    //* const sectionNode = iframeElement?.contentDocument?.querySelector(
    //*   `[data-section-toggle="${section.name}"]`,
    //* );
    const sectionNode = iframeElement?.contentDocument?.getElementById(
      section.id,
    );
    sectionNode?.remove();
  };

  useEffect(() => {
    const iframeElement = document.querySelector("#viewport-preview");

    iframeElement?.contentDocument?.documentElement.classList.add(
      "sections-edit",
    );

    return () => {
      iframeElement?.contentDocument?.documentElement.classList.remove(
        "sections-edit",
      );
    };
  }, []);

  useEffect(() => {
    switchSectionActiveVariantActions(version);
  }, [version]);

  return (
    <DialogContent
      open={true}
      // onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Sections</DialogTitle>

      <div
        style={{
          backgroundColor: theme.palette.background.paper,
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 3, 3),
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2),
          maxHeight: "350px",
          overflowY: "auto",
        }}
      >
        <FormGroup>
          {sectionsToToggle.map((section, index) => {
            return (
              <Grid
                container
                spacing={2}
                key={section.id}
                sx={{
                  "&:not(:last-child)": {
                    mb: 1,
                  },
                }}
                flexWrap={"nowrap"}
              >
                <Grid
                  item
                  // xs={7}
                  flexGrow={1}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={section.visible}
                        onChange={(e) => handleSectionsSave(e, section.id)}
                      />
                    }
                    label={section.name}
                  />
                </Grid>
                <Grid
                  item
                  xs={"auto"}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <TextField
                    sx={{
                      width: 60,
                    }}
                    InputProps={{
                      sx: { "& input": { textAlign: "center" } },
                    }}
                    size="small"
                    value={section.order || index + 1}
                    label="Order"
                    onChange={(e) => handleSectionOrder(e, section.id)}
                  />
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <Tooltip title="Duplicate this section">
                    <Box>
                      <IconButton
                        onClick={() => handleCreateDuplicate(section, index)}
                      >
                        <ControlPointDuplicateOutlined />
                      </IconButton>
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs="auto"
                >
                  <IconButton
                    disabled={!section.duplicateId}
                    sx={{
                      visibility: !section.duplicateId ? "hidden" : null,
                    }}
                    onClick={() => handleDeleteDuplicate(section)}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </FormGroup>
      </div>
    </DialogContent>
  );
};

export default SectionsToggle;
