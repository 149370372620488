import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Slider,
  Typography,
  Grid,
  Popper,
  DialogActions,
  Button,
} from "@mui/material";
 
import {
  setElPositionStyles, 
  handleUpdateGlobalDesignObject,
  getGlobalDesignObject
} from "../../Services";

const PopupElementPosition = ({
  currentElement,
  onClose,
  saveDesignObject,
}) => {
  const [initialPosition, setInitialPosition] = useState({
    top: null,
    left: null,
    marginTop: null,
    marginLeft: null,
    marginBottom: null,
    marginRight: null,
  });

  const saveElementPstn = (initialPosition, elementId) => {
    const savedStyles = getGlobalDesignObject() || {};
    // console.log('saveElementPstn_savedStyles',savedStyles)
    savedStyles["elPosition"] =
      savedStyles["elPosition"] || {};

    savedStyles["elPosition"][
      elementId
    ] = {
      top: initialPosition.top !== null ? `${initialPosition.top}` : "auto",
      left: initialPosition.left !== null ? `${initialPosition.left}` : "auto",
      marginTop:
        initialPosition.marginTop !== null
          ? `${initialPosition.marginTop}`
          : "auto",
      marginLeft:
        initialPosition.marginLeft !== null
          ? `${initialPosition.marginLeft}`
          : "auto",
      marginBottom:
        initialPosition.marginBottom !== null
          ? `${initialPosition.marginBottom}`
          : "auto",
      marginRight:
        initialPosition.marginRight !== null
          ? `${initialPosition.marginRight}`
          : "auto",
    };

    // localStorage.setItem("elementStyles", JSON.stringify(savedStyles));
    handleUpdateGlobalDesignObject(savedStyles, "saveElementPstn");

    saveDesignObject();
  };

  const setInitialElementPstn = (el) => {
    const computedStyle = window.getComputedStyle(el);

    const newPosition = {
      top:
        (el.style.top !== "auto" ? parseFloat(el.style.top) : null) ||
        parseFloat(computedStyle.top) ||
        null,
      left:
        (el.style.left !== "auto" ? parseFloat(el.style.left) : null) ||
        parseFloat(computedStyle.left) ||
        null,
      marginTop:
        (el.style.marginTop !== "auto"
          ? parseFloat(el.style.marginTop)
          : null) ||
        parseFloat(computedStyle.marginTop) ||
        null,
      marginLeft:
        (el.style.marginLeft !== "auto"
          ? parseFloat(el.style.marginLeft)
          : null) ||
        parseFloat(computedStyle.marginLeft) ||
        null,
      marginBottom:
        (el.style.marginBottom !== "auto"
          ? parseFloat(el.style.marginBottom)
          : null) ||
        parseFloat(computedStyle.marginBottom) ||
        null,
      marginRight:
        (el.style.marginRight !== "auto"
          ? parseFloat(el.style.marginRight)
          : null) ||
        parseFloat(computedStyle.marginRight) ||
        null,
    };

    setInitialPosition(newPosition);
  };

  const setSaveElementPosition = (styles, elementId) => {
    setElPositionStyles(styles, elementId);
    saveElementPstn(styles, elementId);
  };

  useEffect(() => {
    setInitialElementPstn(currentElement);
  }, [currentElement]);

  useEffect(() => {
    function closeOnEsc(e) {
      if (e.key === "Escape") {
        onClose();
      }
    }

    document.addEventListener("keydown", closeOnEsc);

    return () => {
      document.removeEventListener("keydown", closeOnEsc);
    };
  }, []);

  return (
    <DialogContent
      open={true}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Element Position</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          {(initialPosition.top !== null || initialPosition.left !== null) && (
            <>
              <Grid
                item
                xs={12}
              >
                <Typography>Initial Top:</Typography>
                <Slider
                  min={0}
                  max={1000}
                  value={initialPosition.top || 0}
                  onChange={(_, newTop) => {
                    setInitialPosition({ ...initialPosition, top: newTop });
                    setSaveElementPosition(
                      { ...initialPosition, top: newTop },
                      currentElement.id,
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography>Initial Left:</Typography>
                <Slider
                  min={0}
                  max={1000}
                  value={initialPosition.left || 0}
                  onChange={(_, newLeft) => {
                    setInitialPosition({ ...initialPosition, left: newLeft });
                    setSaveElementPosition(
                      { ...initialPosition, left: newLeft },
                      currentElement.id,
                    );
                  }}
                />
              </Grid>
            </>
          )}
          {(initialPosition.marginTop !== null ||
            initialPosition.marginLeft !== null ||
            initialPosition.marginBottom !== null ||
            initialPosition.marginRight) && (
            <>
              <Grid
                item
                xs={12}
              >
                <Typography>Initial Margin Top:</Typography>
                <Slider
                  min={0}
                  max={1000}
                  value={initialPosition.marginTop || 0}
                  onChange={(_, newMarginTop) => {
                    setInitialPosition({
                      ...initialPosition,
                      marginTop: newMarginTop,
                    });
                    setSaveElementPosition(
                      { ...initialPosition, marginTop: newMarginTop },
                      currentElement.id,
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography>Initial Margin Left:</Typography>
                <Slider
                  min={0}
                  max={1000}
                  value={initialPosition.marginLeft || 0}
                  onChange={(_, newMarginLeft) => {
                    setInitialPosition({
                      ...initialPosition,
                      marginLeft: newMarginLeft,
                    });
                    setSaveElementPosition(
                      { ...initialPosition, marginLeft: newMarginLeft },
                      currentElement.id,
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography>Initial Margin Bottom:</Typography>
                <Slider
                  min={0}
                  max={1000}
                  value={initialPosition.marginBottom || 0}
                  onChange={(_, newMarginBottom) => {
                    setInitialPosition({
                      ...initialPosition,
                      marginBottom: newMarginBottom,
                    });
                    setSaveElementPosition(
                      { ...initialPosition, marginBottom: newMarginBottom },
                      currentElement.id,
                    );
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Typography>Initial Margin Right:</Typography>
                <Slider
                  min={0}
                  max={1000}
                  value={initialPosition.marginRight || 0}
                  onChange={(_, newMarginRight) => {
                    setInitialPosition({
                      ...initialPosition,
                      marginRight: newMarginRight,
                    });
                    setSaveElementPosition(
                      { ...initialPosition, marginRight: newMarginRight },
                      currentElement.id,
                    );
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </DialogContent>
  );
};

export default PopupElementPosition;
