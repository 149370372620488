import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

const PortalContainer = ({ children, targetNode }) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    // Get the wrapper element from the provided wrapperNode or use the body element
    const wrapper = targetNode
      ? targetNode
      : document.querySelector(".sidebars-wrapper");

    // Create a new div element and append it to the wrapper
    const newContainer = document.createElement("div");
    if (!targetNode) {
      newContainer.style.position = "fixed";
      newContainer.style.zIndex = "1200";
      newContainer.style.height = "100vh";
      newContainer.style.overflowY = "auto";
    }
    wrapper.appendChild(newContainer);
    setContainer(newContainer);

    // Clean up the portal element when the component is unmounted
    return () => {
      wrapper.removeChild(newContainer);
    };
  }, [targetNode]);

  if (!container) return null;

  return createPortal(children, container);
};

export default PortalContainer;
