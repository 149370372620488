import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Primary color
    },
    secondary: {
      main: "#333333", // Secondary color
    },
    default: {
      main: "#fff", // Default color
    },
    default:{
      main: '#fff', // Default color
    },
    components: {
      // Name of the component
      MuiDialogContent: {
        styleOverrides: {
          // Name of the slot
          root: {
            // Some CSS
            padding: "1rem",
          },
        },
      },
    },
    //   text: {
    //     primary: '#333333', // Primary text color
    //     secondary: '#666666', // Secondary text color
    //   },
    //   background: {
    //     default: '#f5f5f5', // Default background color
    //   },
    // },
    // typography: {
    //   fontFamily: 'Roboto, sans-serif', // Font family
    //   fontSize: 16, // Base font size
    //   h1: {
    //     fontSize: '3rem', // Heading 1 font size
    //   },
    //   h2: {
    //     fontSize: '2.5rem', // Heading 2 font size
    //   },
    // Add more typography configurations as needed
  },
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: '#3f51b5', // Button background color
  //         color: '#ffffff', // Button text color
  //         '&:hover': {
  //           backgroundColor: '#303f9f', // Button hover background color
  //         },
  //       },
  //     },
  //   },
  //   // Add more component-specific configurations as needed
  // },
});

export default theme;
