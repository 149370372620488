import { Box } from "@mui/material";
import React, { useEffect } from "react";

function SuccessMessage({ text, setIsSuccessOpen }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSuccessOpen(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        bgcolor: "success.main",
        color: "success.contrastText",
        p: 2,
        mt: 2,
      }}
    >
      {text}
    </Box>
  );
}

export default SuccessMessage;
