import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import AppSaticPage from './AppSaticPage';
import reportWebVitals from './reportWebVitals';
import './App.css';
// const iframeElement = document.querySelector('#viewport-preview');
// const rootElement = document.getElementById('root');
// export const innerPage = iframeElement?.contentDocument || document;

// if (iframeElement) {
//   iframeElement.addEventListener('load', () => {
//     const iframeRoot = ReactDOM.createRoot(iframeElement.contentDocument.getElementById('root'));
//     iframeRoot.render(
//       <React.StrictMode>
//         <App />
//       </React.StrictMode>
//     );
//   });
// } else {
//   const root = ReactDOM.createRoot(rootElement);
//   root.render(
//     <React.StrictMode>
//       <AppSaticPage />
//     </React.StrictMode>
//   );
// }
const iframeElement = document.querySelector('#viewport-preview');
const rootElement = document.getElementById('root');
export const innerPage = iframeElement?.contentDocument || document;

if (iframeElement) {
  iframeElement.addEventListener('load', () => {
    const iframeRootElement = iframeElement.contentDocument.getElementById('root');
    if (iframeRootElement) {
      const iframeRoot = ReactDOM.createRoot(iframeRootElement);
      iframeRoot.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
    } else {
      console.log('Iframe root element not found, waiting for content to load...');
      
      // Retry rendering the React app after a short delay
      setTimeout(() => {
        const iframeRootElement = iframeElement.contentDocument.getElementById('root');
        if (iframeRootElement) {
          const iframeRoot = ReactDOM.createRoot(iframeRootElement);
          iframeRoot.render(
            <React.StrictMode>
              <App />
            </React.StrictMode>
          );
        } else {
          console.log('Iframe root element still not found, rendering static page...');
          const root = ReactDOM.createRoot(rootElement);
          root.render(
            <React.StrictMode>
              <AppSaticPage />
            </React.StrictMode>
          );
        }
      }, 500);
    }
  });
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <AppSaticPage />
    </React.StrictMode>
  );
}