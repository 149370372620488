import { styled } from "@mui/material";
import React from "react";

export const ColorInput = styled("input")({
  "-webkit-appearance": "none",
  border: "none",
  width: 20,
  height: 20,
  cursor: "pointer",
  padding: 0,
  margin: 0,
  backgroundColor: "transparent",
  "&::-webkit-color-swatch-wrapper": {
    padding: 0,
  },
  "&::-webkit-color-swatch": {
    border: "none",
    borderRadius: 3,
  },
  "&::-moz-color-swatch": {
    border: "none",
    borderRadius: 3,
  },
});
