import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  InputAdornment,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import PortalContainer from "./PortalContainer";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import MinimizeIcon from "@mui/icons-material/Minimize";
const ViewportResizer = ({ handleToggleStaticPage, handleResolutionMode }) => {
  const theme = useTheme();
  const savedSettings = JSON.parse(localStorage.getItem("viewportSettings"));

  const [viewportSettings, setViewportSettings] = useState(
    savedSettings
      ? savedSettings
      : {
        width: 1920,
        height: 1080,
        zoom: "0.8",
      },
  );
  const iframeRef = useRef(document.querySelector("#viewport-preview"));
  const wrapperRef = useRef(document.querySelector(".wrapper-for-iframe-page"));
  const innerPageRef = useRef(
    document.querySelector("#viewport-preview").contentWindow.document,
  );
  const [isViewPortResizerOpen, setIsViewPortResizerOpen] = useState(true);
  const [updateObserver, setUpdateObserver] = useState(null);

  // console.log('innerPageRef',innerPageRef.current)
  // console.log('ViewportResizer', iframeRef.current, 'width', width, 'height', height, 'scale', scale);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target.id === "wrapper-for-iframe-page") {
          const { width, height, zoom } = viewportSettings;
          const previewElement = iframeRef.current;
          const wrapperElement = previewElement?.closest(
            ".wrapper-for-iframe-page",
          );
          const wrapperWidth = wrapperElement.offsetWidth;
          const newScale = wrapperWidth / width;
          console.log("newScale: ", newScale);

          // console.log('wrapperWidth', wrapperWidth, 'width', width, 'height', height, 'scale', scale);
          setTimeout(() => {
            handleApplyViewport();
          }, 100);
        }
      }
    });

    const wrapperElement = wrapperRef.current;
    // const iframeElement = iframeRef.current;
    if (wrapperElement) {
      resizeObserver.observe(wrapperElement);
    }

    return () => {
      if (wrapperElement) {
        resizeObserver.unobserve(wrapperElement);
      }
    };
  }, [updateObserver]);

  const handleApplyViewport = () => {
    const previewElement = iframeRef.current;
    const wrapperElement = previewElement?.closest(".wrapper-for-iframe-page");
    // const innerPage = innerPageRef.current;
    const { width, height, zoom } = viewportSettings;
    console.log("handleApplyViewport", width, height, zoom);
    if (previewElement && wrapperElement) {
      const wrapperWidth = wrapperElement.offsetWidth * zoom;
      const newScale = wrapperWidth / width;
      const topOffset = (height - height * newScale) / 2;

      console.log("wrapperWidth:", wrapperWidth, width, newScale);
      const leftOffset = (wrapperWidth - width) / 2;
      if (width > wrapperWidth) {
        previewElement.style.transform = `scale(${newScale}) translate(-50%, -50%)`;
        previewElement.style.left = "calc(50% + " + leftOffset + "px)";
        previewElement.style.top = "calc(50vh - " + topOffset + "px)";
        console.log(
          "wrapperWidth:",
          wrapperWidth,
          "newScale: ",
          newScale,
          "previewElement:",
          previewElement,
        );
      } else {
        const newScale = wrapperWidth / (width * zoom);
        // const newScale = wrapperWidth / (width * zoom);
        const topOffset = (height - height) / 2;
        const leftOffset = (wrapperWidth - width * zoom) / 2;
        console.log(
          "handleApplyViewportELSE",
          wrapperWidth,
          width,
          newScale,
          topOffset,
          leftOffset,
        );
        previewElement.style.transform = `scale(${zoom}) translate(-50%, -50%)`;
        previewElement.style.left = "50%";
        previewElement.style.top = "calc(50vh - " + topOffset + "px)";
      }
      console.log("handleApplyViewport2: ", width, height);
      previewElement.style.width = width + "px";
      previewElement.style.height = height + "px";
    }

    handleResolutionMode();

  };

  useEffect(() => {
    handleApplyViewport();
  }, []);

  const handleScaleChange = (e) => {
    let value = e.target.value;

    // If the input starts with '0' and the second character is not a dot, add a dot
    if (value.startsWith("0") && value.length === 1) {
      value += ".";
    }

    // Ensure only valid numbers are alloweda
    const updatedSettings = { ...viewportSettings, zoom: value };
    localStorage.setItem("viewportSettings", JSON.stringify(updatedSettings));
    setViewportSettings(updatedSettings);

    // if (/^\d*\.?\d*$/.test(value)) {
    //   // Calculate the maximum possible scale based on the wrapper's width
    //   const maxScale = wrapperRef.current.offsetWidth / iframeRef.current.offsetWidth;

    //   // Ensure the scale value does not exceed the maximum possible scale
    //   if (parseFloat(value) > maxScale) {
    //     setZoom(maxScale.toFixed(2));
    //   } else {
    //     setZoom(value);
    //   }
    // }
  };

  const handleWidthChange = (e) => {
    let value = e.target.value;
    if (value === "0") {
      value = "";
    }

    // local
    const updatedSettings = {
      ...viewportSettings,
      width: value ? Number(Math.floor(value)) : "",
    };
    localStorage.setItem("viewportSettings", JSON.stringify(updatedSettings));
    setViewportSettings(updatedSettings);
  };

  const handleHeightChange = (e) => {
    let value = e.target.value;

    if (value === "0") {
      value = "";
    }
    const updatedSettings = {
      ...viewportSettings,
      height: value ? Number(Math.floor(value)) : "",
    };
    localStorage.setItem("viewportSettings", JSON.stringify(updatedSettings));
    setViewportSettings(updatedSettings);
  };

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem("viewportSettings"));
    if (savedSettings) {
      setViewportSettings(savedSettings);
    }
  }, []);

  if (isViewPortResizerOpen) {
    return (
      <PortalContainer
        targetNode={document.querySelector(".viewport-reize-wrapper")}
      >
        <Box
          className=""
          sx={{
            position: "relative",
            background: theme.palette.background.paper,
            // padding: theme.spacing(1),
            borderRadius: theme.spacing(1),
            // boxShadow: theme.shadows[3],
          }}
        >
          <CloseIcon
            className="sidebar-menu__close-btn"
            style={{
              right: "auto",
              left: "-30px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={() => setIsViewPortResizerOpen(false)}
          />

          <Box
            className="viewport-resizer"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: theme.spacing(1),
            }}
          >
            <TextField
              label="Width"
              type="number"
              value={viewportSettings.width}
              onChange={handleWidthChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
              }}
              size="small"
            />
            <TextField
              label="Height"
              type="number"
              value={viewportSettings.height}
              onChange={handleHeightChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
              }}
              size="small"
            />
            <TextField
              label="Zoom"
              type="text"
              value={viewportSettings.zoom}
              onChange={handleScaleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">x</InputAdornment>,
              }}
              size="small"
            />
            <div style={{ display: "flex", gap: "15px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleApplyViewport();
                  setUpdateObserver(Math.random());
                  window.location.reload()
                }}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Apply
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleToggleStaticPage}
                sx={{ fontSize: 12, height: 40, textTransform: "capitalize" }}
              >
                Live preview
              </Button>
            </div>
          </Box>
          {/* <iframe ref={iframeRef} id="viewport-preview" src="./static-page.html" frameBorder={0} /> */}
        </Box>
      </PortalContainer>
    );
  } else {
    return (
      <PortalContainer
        targetNode={document.querySelector(".viewport-reize-wrapper")}
      >
        <AspectRatioIcon
          style={{
            cursor: "pointer",
            zIndex: "1",
            position: "fixed",
            right: "10px",
            top: "10px",
          }}
          onClick={() => setIsViewPortResizerOpen(true)}
        />
      </PortalContainer>
    );
  }
};

export default ViewportResizer;
