import React, { useEffect, useState } from "react";
import { auth, provider } from "../googleAuth/config";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import { Button } from "@mui/material";

function GoogleAuth({ handleUserEmail, setVersions, isTokenValid }) {
  // console.log('handleUserEmail', handleUserEmail)
  const [authButtonText, setAuthButtonText] = useState("Login with Google");
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Check the user's authentication status on component mount
    checkUserAuthStatus();
  }, []);

  useEffect(() => {
    if (userData && handleUserEmail) {
      handleUserEmail(userData.email); // for testing purpose, remove it later
    }
  }, [userData]);

  const checkUserAuthStatus = () => {
    onAuthStateChanged(auth, (user) => {
      // console.log('checkUserAuthStatus', user);
      if (user) {
        localStorage.setItem("userEmail", JSON.stringify(user.email)); // for testing purpose, remove it later
        setUserData(user);
        // User is authenticated, update the button text
        setAuthButtonText("Logout");
      } else {
        // User is not authenticated, update the button text
        localStorage.removeItem("userEmail");
        setUserData(null);
        setAuthButtonText("Login with Google");
      }
    });
  };

  const handleAuth = () => {
    if (authButtonText === "Logout") {
      // User is authenticated, perform logout
      logout();
    } else {
      // User is not authenticated, perform sign-in
      signInWithGoogle();
    }
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // User successfully authenticated, update the button text
        console.log(
          "Successfully authenticated with Google using OAuth",
          result.user,
        );
        localStorage.setItem(
          "googleAccessToken",
          JSON.stringify(result.user.accessToken),
        );
        // handleUserEmail(result.user.email)
        setUserData(result.user);
        setAuthButtonText("Logout");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        // User successfully logged out, update the button text
        setAuthButtonText("Login with Google");
        localStorage.clear();
        setUserData(null);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // if the token has expired, logout the user
    if (!isTokenValid) {
      logout();
    }
  }, [isTokenValid]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleAuth}
      style={{ marginTop: "40px" }}
    >
      {authButtonText}
    </Button>
  );
}

export default GoogleAuth;
