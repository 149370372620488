import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  FormControl,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popper,
} from "@mui/material";

const ImageUploader = ({ handleSaveImage }) => {
  const [file, setFile] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [error, setError] = useState(null);

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      await handleUpload(selectedFile);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("action", "external_upload_image");

    try {
      const response = await axios.post(
        "https://design.codersuccess.com/wp-admin/admin-ajax.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setUploadedImageUrl(response.data);
      setError(null);
    } catch (err) {
      setError("Error uploading image");
      console.error(err);
    }
  };

  return (
    <DialogContent open={true}>
      <DialogTitle>Upload Image</DialogTitle>
      <DialogContent style={{ maxHeight: "350px", overflowY: "auto" }}>
        <FormControl
          fullWidth
          margin="normal"
        >
          <FormLabel>Select Image</FormLabel>
          <TextField
            type="file"
            inputProps={{
              accept: "image/png, image/gif, image/jpeg, image/jpg, image/svg",
            }}
            onChange={handleFileChange}
            variant="outlined"
          />
        </FormControl>

        {uploadedImageUrl && (
          <div>
            <Typography
              variant="h6"
              gutterBottom
            >
              Image Uploaded Successfully. Image URLs:
            </Typography>
            <List>
              {Object.entries(uploadedImageUrl).map(([size, url]) => (
                <ListItem key={size}>
                  <ListItemText
                    primary={size}
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSaveImage(url)}
                      >
                        {url}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        )}

        {error && (
          <Typography
            variant="body1"
            color="error"
          >
            {error}
          </Typography>
        )}
      </DialogContent>
    </DialogContent>
  );
};

export default ImageUploader;
