import React, { useEffect } from 'react'

function StaticPage() {

  useEffect(() => {
    const body = document.querySelector('body');
      body.classList.add('static-page')
      body.classList.remove('has-sidebar')
      body.classList.remove('iframe-page-body')

    const iframe = document.querySelector('#viewport-preview')
    iframe.style = ''
  }, [])
  
  return null
  
  // (
  //   <iframe id="static-page" className='' src="./static-page.html" frameborder="0"></iframe>
  // )
}

export default StaticPage